$(function () {
    /**
     * Related blades info for optional future separated packages:
     * product.blade.php
     */
    $('.product-large-preview-cnt img').on('click', function() {
       if($(this).data('img-url').length) {
           $('.product-large-img img').prop('src', $(this).data('img-url'));
       }
    });

    $('.mix-version-selector').on('change', function () {
        if ($(this).find(':selected').data('img-url').length) {
            $('.product-large-img img').prop('src', $(this).find(':selected').data('img-url'));
        }

        if ($(this).find(':selected').data('price').length) {
            $('.product-large-price').html($(this).find(':selected').data('price'));
        }

        $('.product-stock').hide();
        if (!isNaN($(this).find(':selected').data('product-id'))) {
            $('.product-stock[data-product-id="' + $(this).find(':selected').data('product-id') + '"]').show();
        }
    });

    $('.mix-version-selector').change();

    /**
     * Related blades info for optional future separated packages:
     * cart.blade.php
     */
    $('.submit-on-change').on('change', function() {
        $(this).closest('form').trigger('submit');
    });

    /**
     * Related blades info for optional future separated packages:
     * product.blade.php
     * brand.blade.php
     * cart.blade.php
     */
    $('.item').each(function (i) {
        var iLength = $(this).parent().find('.item').length;
        var blocksPerRow = 4;

        /*
        if ($(window).width() <= 463) {
            blocksPerRow = 1;
        } else if ($(window).width() <= 749) {
            blocksPerRow = 2;
        } else if ($(window).width() <= 964) {
            blocksPerRow = 3;
        }
        */

        if (((i) % blocksPerRow ) === 0) {
            var next = $(this).nextAll();
            next.children(':first-child').slice(0, 3).clone().appendTo($(this));
        } else {
            if ($(this).hasClass('active')) {
                $(this).next().addClass('active');
            }
            $(this).remove();
        }

        if (iLength <= blocksPerRow) {
            $(this).parent().parent().parent().prev().find('.carousel-nav').hide();
        }
    });

    /**
     * Sexy radio visual aid
     * form-user.blade.php
     */
    $('input:radio').change(function() {
        $('div.radio').removeClass('selected');
        $('input:radio:checked').closest('div.radio').addClass('selected');
    }).change();;


    var sqResults = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        identify: function(obj) { return obj.id; },
        remote: {
            url: '/' + $('.form-control-search').data('locale-prefix') + '/search/%QUERY',
            wildcard: '%QUERY',
            cache: false
        }
    });

    $('.form-control-search').typeahead({
        minLength: 3,
        limit: 10,
        highlight: true
    },{
        source: sqResults,
        display: 'content_title',
        templates: {
            suggestion: function (data) {
                return '<div><span class="title">' + data.navigation_title + '</span><span class="number"> (' + data.article_number + ')</span></div>';
            }
        }
    }).on('typeahead:asyncrequest', function (ev) {
        $('.tt-spinner').show();
    }).on('typeahead:selected', function (ev, obj) {
        window.location = obj.canonical_url;
    })  .on('typeahead:asynccancel typeahead:asyncreceive', function() {
        $('.tt-spinner').hide();
    });




    (function ($) {
        $.fn.inlineStyle = function (prop) {
            return this.prop("style")[$.camelCase(prop)];
        };
    }(jQuery));

    var menuContainer = $(".nav-main-sm-container");

    $(".nav-bars").click(function() {
        menuContainer.slideToggle();
    });

    if ($(window).width() < 640) {
        var screenSize = 'small';
        setNavInFloats();
        menuContainer.hide();

    } else if ($(window).width() < 991 && ($(window).width() >= 640)) {
        var screenSize = 'medium';
        setNavInColumns();
        menuContainer.hide();

    } else {
        resetNav();
        var screenSize = 'large';
    }

    $(window).on("resize", function() {
        if ($(window).width() < 992 && $(window).width() >= 640 && screenSize != 'medium') {
            screenSize = 'medium';
            setNavInColumns();
            if (menuContainer.inlineStyle("display") != 'block') {
                menuContainer.hide();
            }
        } else if ($(window).width() < 640 && screenSize != 'small') {
            screenSize = 'small';
            setNavInFloats();
            if (menuContainer.inlineStyle("display") != 'block') {
                menuContainer.hide();
            }
        } else if ($(window).width() > 991 && screenSize != 'large') {
            resetNav();
            screenSize = 'large';
            menuContainer.css('display', '');
        }
    });

    function setNavInColumns() {
        resetNav();
        $('.nav-main-child-ul').hide();
        $(".nav-main-a-expand").css("display", 'inline-block');
        var items2 = $('.items2');
        var left_column_height = 0;
        var middle_column_height = 0;
        var right_column_height = 0;

        for (var i = 0; i < items2.length; i++) {
            if (left_column_height > right_column_height && middle_column_height > right_column_height) {
                right_column_height+= items2.eq(i).outerHeight(true);
                items2.eq(i).clone(true, true).appendTo($('.nav-main-ul-right'));
                items2.eq(i).hide();

            } else if (left_column_height > middle_column_height) {
                middle_column_height+= items2.eq(i).outerHeight(true);
                items2.eq(i).clone(true, true).appendTo($('.nav-main-ul-middle'));
                items2.eq(i).hide();

            } else {
                left_column_height+= items2.eq(i).outerHeight(true);
            }
        }
    }

    function setNavInFloats() {
        resetNav();
        $('.nav-main-child-ul').hide();
        $(".nav-main-a-expand").css("display", 'inline-block').text("+");
        var items2 = $('.items2');
        var left_column_height = 0;
        var right_column_height = 0;

        for (var i = 0; i < items2.length; i++) {
            if (left_column_height > right_column_height) {
                right_column_height+= items2.eq(i).addClass('right').outerHeight(true);
            } else {
                left_column_height+= items2.eq(i).outerHeight(true);
            }
        }
    }

    function resetNav() {
        $(".nav-main-ul-middle").empty();
        $(".nav-main-ul-right").empty();
        $(".nav-main-ul > li").css("display", '').removeClass('right');
        $('.nav-main-child-ul').css("display", '');
        $(".nav-main-a-expand").hide().text("+");
    }

    $(".nav-main-a-expand").click(toggleCategories);

    function toggleCategories() {
        $(this).text() == '+' ? $(this).text('-') : $(this).text('+');
        $(this).parent().children(".nav-main-child-ul").slideToggle();
    }

    $('.clickonce').click(function (event) {
        if ($(this).hasClass("clicked")) {
            event.preventDefault();
        }
        $(this).addClass("clicked");
    });
});